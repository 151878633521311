import {
    Link as ChakraLink,
    Text,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Image, 
    Heading, 
    Badge,
    Avatar,
    Tabs,
    TabList,
    Tab,
    Flex,
    TabIndicator,
    Spacer,
    Box,
    Center, 
    useToast
  } from '@chakra-ui/react'

import { useRouter } from 'next/router'
import {IoSettings,IoCart, IoHelpCircle, IoReceipt, IoEarth,IoExit, IoBarChart, IoBriefcase, IoAlarm, IoHandLeft, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle, IoGlobe, IoCopy, IoRibbon, IoCaretForward} from 'react-icons/io5';

import { FeedbackFish } from '@feedback-fish/react'

import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { resolve } from 'path';
import { updateLayoutMeasurement } from 'framer-motion/types/render/dom/projection/utils';
import InviteUserModal from './InviteUserModal';
import { round } from 'lodash';
import TutorialModal from './TutorialModal';
import Countdown from 'react-countdown';

// Renderer callback with condition
const rendererTimer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <div />;
  } else {
    // Render a countdown
    return <span>{minutes} min. {seconds} sec.</span>;
  }
};

const MenuElement = ({sessionData, openInvoice, openTravelExpense, onUpgradeOpen }) => {
  
    const { isOpen: isUserOpne, onOpen: onUserOpen, onClose: onUserClose } = useDisclosure();

    const { isOpen: isTutorialOpen, onOpen: onTutorialOpen, onClose: onTutorialClose } = useDisclosure()
    
    const router = useRouter();
    const newUserAllDataAdded = false;
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [uploadInProgress, setUploadInProgress] = useState(false);

    const elementRef = useRef(null);

    const api_url = process.env.NEXT_PUBLIC_API_URL
    const toast = useToast()

    const [tabIndex, setTabIndex] = useState(0);
    const [tabArray, setTabArray] = useState([])

    const navigateToTab = async (pageName)=>{ 
      router.push(pageName);
    }

    const userNotification = async (title, subTitle, status) => {
      toast({
        title: title,
        description: subTitle,
        status: status,
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      })
    }
    const ProfilePictureUploadRef = useRef(null);

    const uploadProPic = async(data, returnOnlyData = false) =>{
    
      if(returnOnlyData == false){
        setUploadInProgress(true)
      }
      var formData = new FormData();
      formData.append('file', data)
      const fetchData = await fetch(`${api_url}/files/upload/?disableAi=true`, {
        headers: {
          'Authorization': `Bearer ${sessionData.accessToken}`,
        },
        credentials: "include",
        method: "Post",
        body: formData
      })
      const response = await fetchData.json();
      if(response.statusCode == 400 || response.statusCode == 500){
        alert("Only PDF, PNG & JPG are allowed");
        setUploadInProgress(false);
        return;
      }
      console.log("response", response);

      if("imageUrl" in response){
        let imageURL = response.imageUrl;
        let body = sessionData.user_data;

        body.profilePicture = imageURL;
        console.log("body:", body);
        const fetchData = await fetch(`${api_url}/users/${body._id}`, {
          method: "PATCH",
          headers: {
            'Authorization': `Bearer ${sessionData.accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
        
        const responseUpdate = await fetchData.json();
        console.log("responseupdate", responseUpdate);
        
        if(fetchData.status == 200){
            userNotification("Successfully Updated", body.username+" has been successfully updated", "success");
        }else{
            if(responseUpdate.message.length == 1){
                userNotification("Error", responseUpdate["message"][0], "error");
            }else{
                userNotification("Error", responseUpdate["message"], "error");
            }
        }
      }else{
        //
      }
      setUploadInProgress(false)
      
    }

    const deleteProfilePicture = async() =>{
      let body = sessionData.user_data;

      body.profilePicture = "";


      const fetchData = await fetch(`${api_url}/users/${body._id}`, {
        method: "PATCH",
        headers: {
          'Authorization': `Bearer ${sessionData.accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      
      const responseUpdate = await fetchData.json();

      
      if(fetchData.status == 200){
          userNotification("Successfully Updated", body.username+" has been successfully updated", "success");
      }else{
          if(responseUpdate.message.length == 1){
              userNotification("Error", responseUpdate["message"][0], "error");
          }else{
              userNotification("Error", responseUpdate["message"], "error");
          }
      }
    }
    


    React.useEffect(() => {
      let tabArrayTemp =[];
      if(sessionData != undefined && (sessionData["usage"]["used"] >= sessionData["usage"]["limit"])){
        limitExceded = true;
      }

      let admin = sessionData.user_data.role == "ADMIN";
      let rights = sessionData.rights; 
      
      if((admin || rights.includes("invoices"))){
        tabArrayTemp.push("/?tabIndexMenu=0");
      }

      if((admin || rights.includes("travel-expenses"))){
        //tabArrayTemp.push("/?tabIndexMenu=1");
      }

      if((admin || rights.includes("statistics"))){
        tabArrayTemp.push("/analytics");
      }

      if((admin || rights.includes("manage-users") || rights.includes("manage-budgets") || rights.includes("manage-groups") || rights.includes("manage-invoice-types")) ){
        tabArrayTemp.push("/settings");
      }

      let tabIndexTemp = tabArrayTemp.findIndex(e => e == router.pathname);

      setTabIndex(tabIndexTemp);

      setTabArray(tabArrayTemp)


    }, [tabIndex])

    let limitExceded = false; 
    
    if(sessionData != undefined && !("usage" in sessionData)){
      window.location.href= `https://rekening.b2clogin.com/rekening.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1_rekening_signin&post_logout_redirect_uri=${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/auth/signout`
    }

    if(sessionData != undefined && (sessionData["usage"]["used"] >= sessionData["usage"]["limit"])){
      limitExceded = true;
    }

    let admin = sessionData.user_data.role == "ADMIN";
    let rights = sessionData.rights; 


    //SignUp Discount Timer

    // Erstelle ein Date-Objekt aus createdAt
    let countdownCreatedAt = new Date(); 
    try {
      countdownCreatedAt = new Date(sessionData.team_data.createdAt); 
    } catch (error) {
      alert("ERROR")
    }
    // Berechne die Endzeit des Countdowns (60 Minuten nach createdAt)
    const countdownEnd = countdownCreatedAt.getTime() + 60 * 60 * 1000 + 500000;
    // Aktuelle Zeit
    const countdownNow = new Date().getTime();
    // Berechne die vergangene Zeit in Millisekunden
    const countdownTimeElapsed = countdownNow - countdownCreatedAt.getTime();

    // Berechne verbleibende Zeit
    let countdownTimeLeft = countdownEnd - countdownNow;


    const menuElement = (
    <div>
    <div className='menu'>
        <div className='content-wrapper'>
        <Flex >
          <Flex>
            <Image src='/img/logo.png' style={{height: "41px", width:"115px"}} mr="2"></Image>
            <div>
            <Tabs height={"60px"} isFitted index={tabIndex} onChange={setTabIndex} variant="unstyled">
              <TabList ref={elementRef}>
                {(admin || rights.includes("invoices")) &&
                <Tab onClick={()=>navigateToTab("/")} data-tab="invoices" className='menu-tab-element'>
                  <IoReceipt fontSize={18}></IoReceipt>
                  <Text fontWeight={"bold"} ml="1" style={{width: "100%"}}>
                    Dashboard
                  </Text>
                </Tab>
                }

                {/*(admin || rights.includes("travel-expenses")) &&
                <Tab id="/?tabIndexMenu=1" onClick={()=>navigateToTab("/?tabIndexMenu=1")} data-tab="travel-expenses" className='menu-tab-element'>
                  <IoEarth fontSize={18}></IoEarth>
                  <Text fontWeight={"bold"} ml="1" style={{width: "100%"}}>
                    Travels
                  </Text>
                </Tab>
              */}

                {(admin || rights.includes("statistics")) &&
                <Tab onClick={()=>navigateToTab("analytics")} data-tab="statistics" className='menu-tab-element'>
                  <IoBarChart fontSize={18}></IoBarChart>
                  <Text fontWeight={"bold"} ml="1" style={{width: "100%"}}>
                    Analytics
                  </Text>
                </Tab>
                }
                
                {(admin || rights.includes("manage-users") || rights.includes("manage-budgets") || rights.includes("manage-groups") || rights.includes("manage-invoice-types")) &&
                <Tab onClick={()=>navigateToTab("settings")} data-tab="settings" className='menu-tab-element' >
                  <IoSettings fontSize={20}></IoSettings>
                  <Text fontWeight={"bold"} ml="1" style={{width: "100%"}}>
                    Settings
                  </Text>
                </Tab>
                }
              </TabList>
              <TabIndicator
                mt="10px"
                height="4px"
                bg="#F39F50"
              />
            </Tabs>
            </div>
          </Flex>
          <Spacer />
          <Flex >
            {(router.pathname == "/?tabIndexMenu=0" || router.pathname == "/") && limitExceded != true &&
            <Button colorScheme='rekening.main' mr="3" onClick={()=>{openInvoice("invoice")}}>
                <IoReceipt color="black" />
                <Text color="black" ml="1">Add Invoice</Text>
            </Button>
            }
            {(router.pathname == "/?tabIndexMenu=0" || router.pathname == "/") && (rights.includes("travel-expenses"))  && limitExceded != true &&
            <Button colorScheme='rekening.main' mr="3" onClick={()=>{openInvoice("travel-expense")}}>
                <IoEarth color="black" />
                <Text color="black" ml="1">Add Travel Expense</Text>
            </Button>
            }
            {(router.pathname == "/settings"  || tabIndex == 2 ) && rights.includes("invite-users") &&
            <Button colorScheme='rekening.main' mr="3" onClick={()=>{onOpen()}}>
                <IoPersonAdd color="black" />
                <Text color="black" ml="1">Add User</Text>
            </Button>
            }
            {limitExceded &&
            <Button colorScheme='rekening.main' mr="3" onClick={()=>{onUpgradeOpen()}}>
                <IoCart color="black" />
                <Text color="black" ml="1">Upgrade Plan</Text>
            </Button>
            }
            <a href="mailto:kontakt@rekening.de?subject=Rekening Support Request">
              <Button mr="2">Help</Button>
            </a>
            <Button mr="2" onClick={()=>{onOpen()}}>
                <IoPersonAdd color="black" />
            </Button>
            <Button style={{cursor: "help"}} onClick={()=>{onTutorialOpen()}}>
                <IoHelpCircle color="black" />
            </Button>
            <Avatar onClick={()=>onUserOpen()} style={{cursor: "pointer"}} bgColor="white" size='sm'  marginTop={1} marginLeft={4}  mr={4}  icon={<IoPersonCircleSharp color='rekening.main' fontSize='3rem' />} />
          </Flex>
          </Flex>
        </div>
    </div>
    {limitExceded == true && 
    <div className='subscription-banner'  onClick={()=>{onUpgradeOpen()}}>
      <Text fontSize={"md"}>
        You exceded the free usage limit.<br/>
        Please select a subscription to continue working.
      </Text>
      <Center mt="3">
        <IoCart fontSize={"22px"}></IoCart>
      </Center>
    </div>
    }

    <Modal isOpen={isUserOpne} onClose={onUserClose}>
        <ModalOverlay />
        <ModalContent>
        {sessionData != undefined && 
          <ModalHeader>
            {sessionData.user_data.username}
          </ModalHeader>
          }
          <ModalCloseButton />
          {sessionData != undefined && 
          <ModalBody>
            <Box>
              {"user_data" in sessionData && sessionData.user_data.profilePicture != "" &&
                <Avatar float={"left"} marginRight={2} borderRadius={10} height={55} width={55} className='rekening-invoice-avatar' src={sessionData.user_data.profilePicture} />
              }
              {"user_data" in sessionData && sessionData.user_data.profilePicture == "" &&
              <Avatar float={"left"} marginRight={2} borderRadius={10} height={55} width={55} className='rekening-invoice-avatar' name={sessionData.user.name} />
              }
              {"user_data" in sessionData && sessionData.user_data.profilePicture != "" && !uploadInProgress &&
              <Button mt="2" mr="3"
                onClick={deleteProfilePicture}
                isLoading={uploadInProgress}
                >
                  <IoTrash color="black" />
              </Button>
              }
              <Button mt="2"
                onClick={() => ProfilePictureUploadRef.current && ProfilePictureUploadRef.current.click()}
                isLoading={uploadInProgress}
                >
                  <IoCloudUpload color="black" />
                  <Text color="black" ml="1">Upload Picture</Text>
              </Button>
              <input ref={ProfilePictureUploadRef} style={{display: "none"}} type="file" id="file-upload" onChange={(e) => uploadProPic(e.target.files[0], false)}/>
            </Box>
            <Box mt="4">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Name</Text>
              <Input
                placeholder='Type here...'
                value={sessionData["user_data"]["username"]}
                readOnly={true}
              />
            </Box> 
            <Box mt="2">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Team</Text>
              <Input
                placeholder='Type here...'
                value={sessionData["team_data"]["name"]}
                readOnly={true}
              />
            </Box> 
            <Box mt="2">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>E-Mail</Text>
              <Input
                placeholder='Type here...'
                value={sessionData["user_data"]["email"]}
                readOnly={true}
              />
            </Box> 
            
            {(admin || rights.includes("invite-users"))&&
            <Box mt="2">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Invite new users</Text>
              <Button isFullWidth mr="3" onClick={()=>{onOpen()}}>
                  <IoPersonAdd color="black" />
                  <Text color="black" ml="1">Invite User</Text>
              </Button>
            </Box>
            }

            <Text fontSize={"0.8em"} color={"gray.500"} ml="0" mt="3">
              <a target="_blank" href="https://rekening.de/en-allgemeine-geschaftsbedingungen/">Terms of Use</a> | <a target="_blank" href="https://rekening.de/en-privacy-policy/">Privacy Policy</a> | To delete your account please write an email to kontakt@rekening.de
            </Text>
          </ModalBody>
          }

          <ModalFooter>
            <Button onClick={onUserClose} mr={3}>Close</Button>
            <Button onClick={()=>{window.location.href= `https://rekening.b2clogin.com/rekening.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1_rekening_signin&post_logout_redirect_uri=${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/auth/signout`}}>
              <IoExit style={{marginRight: "5px"}} />Sign out
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <TutorialModal isOpen={isTutorialOpen} onClose={onTutorialClose}/>
      <InviteUserModal isOpen={isOpen} onClose={onClose} session={sessionData} />

      {countdownTimeLeft > 0 &&
      <div className="signup-discount-container">
        <Center>
          <Heading size="md" mt="2">
            <Countdown
              date={countdownEnd}
              renderer={rendererTimer}
              onComplete={()=>{countdownTimeLeft = 0}}
            />
          </Heading>
          </Center>
          <Center>
            <Heading size="sm" style={{ display: 'inline' }}>
              90% discount for 12 months subscription with
            </Heading>
            <Heading size="sm" style={{display:"inline", marginLeft: "7px", marginRight: "7px", textDecoration: "underline",textDecorationStyle: "dotted"}}>
              SIGNUP90
            <IoCopy style={{display:"inline", fontSize: "14", marginLeft: "2px", cursor:"pointer"}} onClick={()=>{
                navigator.clipboard.writeText("SIGNUP90")
                .then(() => {
                  userNotification("Copied to Clipboard","Discount Code Successfully Copied","success")
                })
                .catch(err => {
                  console.error('Error copying text: ', err);
                })
              }
              }></IoCopy>
            </Heading>
            <Heading size="sm" style={{ display: 'inline' }}>
              at checkout
            </Heading>
          </Center>
          <Center>
            <Button mt="3" onClick={()=>{onUpgradeOpen()}}>Claim Discount Code Now<IoCaretForward></IoCaretForward> </Button>
          </Center>
      </div>
      }
    </div>
  );
  
  return menuElement;
};

export default MenuElement;